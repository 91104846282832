import styled from "@emotion/styled";
import { device } from "theme";
export const SUserBlock = styled.div`
  text-align: center;
`;

export const SForm = styled.form`
  margin-top: 21px;
`;

export const SBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${device.desktopPlus} {
    flex-direction: row;
  }

  & > div {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

export const SDesc = styled.div`
  & > div {
    height: 100%;
    & div:last-of-type {
      height: 100%;
      & textarea {
        height: 100%;
      }
    }
  }
`;

export const SFormField = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`;

export const SBtns = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding-top: 48px;
  & .MuiButtonBase-root {
    min-width: 190px;
  }

  @media ${device.desktopNeg} {
    margin-left: 0;
    padding-top: 12px;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;

    & .MuiButtonBase-root {
      min-width: 100%;

      &:not(:last-of-type) {
        margin-bottom: 16px;
      }
    }
  }
`;

export const SPrice = styled.div`
  height: 44px;
`;

export const SInp = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const SWrapInp = styled.div`
  margin-bottom: 24px;

  & ${SInp}:first-of-type {
    margin-bottom: 8px;
  }
`;

export const SDelIcon = styled.div<{ isError: boolean }>`
  display: flex;
  align-items: flex-start;
  padding: 10px 4px;
  cursor: pointer;
  height: ${(props) => (props.isError ? "64px" : `44px`)};

  & > svg {
    color: ${(props) => props.theme.mtsColor.icons.secondary.lightMode};
    font-size: 24px;
  }
`;

export const SPlug = styled.div`
  height: 44px;
  width: 30px;
`;

export const SAddNew = styled.div`
  display: flex;
  justify-content: left;
`;
