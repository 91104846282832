import { format } from "date-fns";
import { ru } from "date-fns/locale";

import { CusTypo } from "components/cusTypo/custom-typography";
import { CopyToClipboard } from "components/copyToClipboard/copyToClipboard.component";
import { EMPTY_CELL_VALUE } from "stream-constants/keys-and-constants";
import { getNumberWithSpaces } from "utils/formatters";
import { ITariffRes } from "interface/management";

import { CustomWidthTooltip } from "components/tooltip/tooltip";
import { SClamp } from "./const";

export const constructTableData = (draft: ITariffRes[]) =>
  draft?.map((d) => {
    const { created_time } = d;

    return {
      ...d,
      created_time: new Date(created_time ?? 0),
    };
  });

const renderCell = (val) => {
  switch (val) {
    case "contract_number":
      return {
        Cell: ({ value }) => (
          <CopyToClipboard text={value ?? EMPTY_CELL_VALUE} variant="p4_medium" font="comp" />
        ),
      };
    case "price":
      return {
        Cell: ({ value }) => (
          <CusTypo variant="p4_medium">
            {`${getNumberWithSpaces(value)} ₽` ?? EMPTY_CELL_VALUE}
          </CusTypo>
        ),
      };
    case "created_time":
      return {
        Cell: ({ value }) => (
          <CusTypo variant="p4_regular">
            {value
              ? format(new Date(value), "dd.MM.yyyy HH:mm", {
                  locale: ru,
                })
              : "-"}
          </CusTypo>
        ),
      };
    case "comment":
      return {
        Cell: ({ value }) =>
          value?.length > 44 ? (
            <CustomWidthTooltip
              title={
                <div style={{ width: 290 }}>
                  <CusTypo variant="p4_regular" styles={{ color: "white" }}>
                    {value}
                  </CusTypo>
                </div>
              }
              arrow
              placement="right"
            >
              <SClamp>
                <CusTypo variant="p4_regular">{value ?? EMPTY_CELL_VALUE}</CusTypo>
              </SClamp>
            </CustomWidthTooltip>
          ) : (
            <CusTypo variant="p4_regular">{value ?? EMPTY_CELL_VALUE}</CusTypo>
          ),
      };
    default:
      return {
        Cell: ({ value }) => <CusTypo variant="p4_regular">{value ?? ""}</CusTypo>,
      };
  }
};

export const constructColumns = ({ columns }) => {
  return columns.map((singleColumn) => {
    return {
      ...singleColumn,
      ...renderCell(singleColumn.accessor),
    };
  });
};
