import { queryClient as instance } from "react-query-wrapper";
import { Keys } from "hooks/api/const";

import { fetchProfile } from "services/profile";
import { ROUTE_PERSONAL_AREA } from "stream-constants/route-constants";

export const successHandler = async (navigate, callback, flag = false) => {
  try {
    const prof = await fetchProfile();
    if (prof.ok) {
      const res = await prof.json();
      callback(res);
    } else {
      throw new Error("profile error");
    }
  } catch (err) {
    throw new Error(err);
  }
  if (!flag) {
    navigate(`${ROUTE_PERSONAL_AREA}`);
  }
  setTimeout(
    () =>
      instance.invalidateQueries({
        predicate: (query) => query.queryKey[0] !== Keys.USERS && query.queryKey[1] !== "tenants",
      }),
    400,
  );
};
