export enum HTTPStatusCode {
  OK_200 = 200,
  UNAUTHORIZED_401 = 401,
  NOT_FOUND_404 = 404,
}

export enum DEPARTURE_RESULT {
  NORMAL = "Нормальный",
  IN_WORK = "В работе",
  BREAKING = "Взлом",
  SUSPICION = "Подозрение",
  TO_CHECK = "К проверке",
  NO_ACCESS = "Нет доступа",
  NO_LOAD = "Нет нагрузки",
  DEFECTIVE = "Неисправен",
  REPLACED = "Заменен",

  PAID = "Оплачен",
  NOT_PAID = "К оплате",
  TRANSACTION = "Транзакция",
  NOT_REQUIRE_PAYMENT = "Не требует оплаты",

  EVENT_INFORMATION = "Информация",
  EVENT_WARNING = "Предупреждение",
  EVENT_CRASH = "Авария",

  COMPANY_ACTIVE = "Активна",
  COMPANY_SUSPENDED = "Приостановлена",
  COMPANY_BLOCKED = "Заблокирована",

  ACTIVE = "Активен",
  SUSPENDED = "Приостановлен",
  BLOCKED = "Заблокирован",

  ENABLED_RELE = "Реле включено",
  DISABLED_RELE = "Реле отключено",
  NEW_DEVICE = "Новое устройство",

  ENABLED = "Включено",
  DISABLED = "Отключено",
  NEW = "Новое",
}

// Услуги в профиле
export enum SERVICES {
  LOSSES = "Поиск коммерческих потерь",
  ASKUE = "Облачная платформа учета",
  MINING = "Выявление майнинговых ферм",
}

export const TIME_ZONE = [
  "GMT-1",
  "GMT+0",
  "GMT+1",
  "GMT+2",
  "GMT+3",
  "GMT+4",
  "GMT+5",
  "GMT+6",
  "GMT+7",
  "GMT+8",
  "GMT+9",
];

export const PHONE_MASK = "+7 (999) 999-99-99";
export const INN_MASK = "9999999999";
export const DATE_MASK = "99.99.9999";
export const TIME_MASK = "99:99";
export const EMPTY_CELL_VALUE = "-";
