import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import theme from "theme";

import Icon from "components/icons";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";
import { ModalNotifyBody } from "components/modal-notify-body/modal-notify-body";
import { MTSButton } from "components/mts-button/mts-button";
import { MTSInput } from "components/mts-input";
import { Spacer } from "components/spacer/spacer.component";
import { useAskueMngUpdateStatus } from "hooks/api/askue";
import { EAskueMngStatus } from "interface/askue";
import { CusTypo } from "components/cusTypo/custom-typography";
import {
  calcShema,
  codeByStatus,
  modalDescrByStatus,
  modalTitleByStatus,
  succTitleByStatus,
} from "./utils";
import { SControls, SICon } from "./substyles";
import { SModalTitle } from "components/modal-notify-body/styles";

interface IProp {
  nextStatus: keyof typeof EAskueMngStatus;
  onCancel: () => void;
  id: string;
  tenantTitle: string;
  isOpen?: boolean;
}

export const StatusModal: React.FC<IProp> = ({ id, nextStatus, onCancel, tenantTitle }) => {
  const statusCodeEng = codeByStatus[nextStatus];
  const isComment = statusCodeEng === "reject";
  const currSchema = calcShema(statusCodeEng === "reject");

  const {
    register,
    control,
    handleSubmit,
    formState: { dirtyFields, isValid, errors },
    getValues,
  } = useForm({
    defaultValues: { user_comment: "" },
    resolver: yupResolver(currSchema),
    mode: "onChange",
  });

  const updateStatus = useAskueMngUpdateStatus();
  const descr =
    nextStatus === "В работе" ? (
      <>
        {`
	  Заявке будет присвоен статус`}{" "}
        <CusTypo variant="p4_regular" styles={{ color: theme.mtsColor.base.idle }}>
          “В работе”
        </CusTypo>
        {`. Пользователь не\u00A0сможет добавлять новые устройства`}
      </>
    ) : (
      modalDescrByStatus[statusCodeEng]
    );

  const onSubmit = (sbmt) => {
    updateStatus.mutate(
      {
        appl_id: String(id),
        comment: dirtyFields["user_comment"] ? sbmt.user_comment : undefined,
        status: nextStatus,
      },
      {
        onSuccess: () => {
          StorageAPI.save(EStorageKeys.ASKUE_MNG_SUCCESS, "on");
        },
      },
    );
  };

  return updateStatus.isError ? (
    <ModalNotifyBody
      title="Операция не выполнена"
      isError
      textDescrioption={updateStatus?.error?.message ?? "Попробуйте повторить действие еще раз"}
      close={onCancel}
    />
  ) : updateStatus.isSuccess ? (
    <SuccessModal
      tenantTitle={tenantTitle}
      subtitle={getValues("user_comment")}
      statusCode={statusCodeEng}
      onCancel={onCancel}
    />
  ) : (
    <>
      <SModalTitle>{modalTitleByStatus[statusCodeEng]}</SModalTitle>
      <Spacer value="16px" />
      <CusTypo variant="p4_regular">{descr}</CusTypo>
      <Spacer value="24px" />
      {isComment ? (
        <>
          <MTSInput
            placeholder="Оставьте комментарий"
            type="textarea"
            label={`Укажите причину отклонения заявки`}
            {...register("user_comment", {
              required: false,
            })}
            control={control}
            errorMessage={errors?.user_comment?.message}
          />
          <Spacer value="24px" />
        </>
      ) : null}
      <SControls>
        <MTSButton
          size="M"
          variant="primary"
          type="submit"
          disabled={!isValid}
          onClick={handleSubmit(onSubmit, undefined)}
        >
          Подтвердить
        </MTSButton>
        <MTSButton size="M" variant="secondary" onClick={onCancel}>
          Отмена
        </MTSButton>
      </SControls>
    </>
  );
};

interface ISuccessModal {
  tenantTitle: string;
  subtitle: string;
  statusCode: string;
  onCancel: () => void;
}
const SuccessModal: React.FC<ISuccessModal> = ({ tenantTitle, statusCode, subtitle, onCancel }) => {
  return (
    <>
      {statusCode === "accept" || statusCode === "in_work" ? (
        <>
          <SICon>
            <Icon.SuccessShaped sx={{ width: 44, height: 44 }} />
          </SICon>
          <Spacer value="16px" />
        </>
      ) : null}
      <CusTypo
        variant="h3_bold"
        styles={{ textAlign: "center", display: "block", whiteSpace: "pre-wrap" }}
      >
        {succTitleByStatus[statusCode]}
      </CusTypo>
      {calcSubtitle(statusCode, tenantTitle, subtitle)}
      <Spacer value="16px" />
      <SControls>
        <MTSButton size="M" variant="secondary" onClick={onCancel}>
          Закрыть
        </MTSButton>
      </SControls>
    </>
  );
};

function calcSubtitle(statusCode, title, subtitle) {
  const descr = statusCode === "accept" ? <>{`Список устройств ${title} обновлен`}</> : subtitle;
  return (
    <>
      <Spacer value="16px" />
      <CusTypo variant="p4_regular" styles={{ textAlign: "center", display: "block" }}>
        {descr}
      </CusTypo>
    </>
  );
}
