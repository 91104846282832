const PREFIX = "ET_";

export enum EStorageKeys {
  MOCKS_STATUS = "MOCKS_STATUS",
  DEMO_STATUS = "DEMO_STATUS",
  ANMS_FILTER = "ANMS_FILTER",
  ANMS_FILTER_IS = "ANMS_FILTER_IS",
  RESEARCH_FILTER_IS = "RESEARCH_FILTER_IS",
  RESEARCH_SETTINGS = "RESEARCH_SETTINGS",
  RESEARCH_SAVED = "RESEARCH_SAVED",
  API_PREFIX = "API_PREFIX",
  ASKUE_MNG_SUCCESS = "ASKUE_MNG_SUCCESS",
  ASKUE_FILTER = "ASKUE_FILTER",
  ASKUE_FILTER_IS = "ASKUE_FILTER_IS",
  ASKUE_POWER_SAVED = "ASKUE_POWER_SAVED",
  ASKUE_SETTINGS = "ASKUE_SETTINGS",
  DEP_FILTER = "DEP_FILTER",
  RESEARCH_HEIGHT = "RESEARCH_HEIGHT",
}

function save(key: EStorageKeys, data: string): void {
  if (!data) {
    return;
  }

  localStorage.setItem(`${PREFIX}${key}`, data);
  const event = new Event("localStorageItemChanged");
  window.dispatchEvent(event);
}

function remove(key: EStorageKeys): void {
  localStorage.removeItem(`${PREFIX}${key}`);
}

function get(key: EStorageKeys): string {
  return localStorage.getItem(`${PREFIX}${key}`) || "";
}

export const StorageAPI = {
  save,
  get,
  remove,
};
