import { format } from "date-fns";
import { isEmpty } from "lodash";

import { IBreadcrumbsLink } from "interface/common";
import { EAskueMngStatus, IAskueEventRequest, IAskueListResponse } from "interface/askue";
import { DEPARTURE_RESULT } from "stream-constants/keys-and-constants";
import {
  ROUTE_ASKUE_DEVICES,
  ROUTE_ASKUE_EVENTS,
  ROUTE_ASKUE_PAGE,
  ROUTE_ASKUE_INSTRUCTIONS,
} from "stream-constants/route-constants";
import { SLink } from "./styles";
import { MAIL_SUPPORT } from "services/urls";

import instruction_1 from "./files/sim_M234.pdf";
import instruction_2 from "./files/sim_M204.pdf";
import instruction_3 from "./files/connect_M234.pdf";
import instruction_4 from "./files/connect_M204.pdf";
import instruction_5 from "./files/login_lk_askue.pdf";
import instruction_6 from "./files/reg_lk_energosfera.pdf";

export const links_root = [{ name: "АСКУЭ", href: `/${ROUTE_ASKUE_PAGE}` }];
export const links_devices: IBreadcrumbsLink[] = [
  { name: "АСКУЭ", href: `/${ROUTE_ASKUE_PAGE}` },
  { name: "Устройства", href: `/${ROUTE_ASKUE_DEVICES}` },
];
export const links_events: IBreadcrumbsLink[] = [
  { name: "АСКУЭ", href: `/${ROUTE_ASKUE_PAGE}` },
  { name: "Журнал событий", href: `/${ROUTE_ASKUE_EVENTS}` },
];

export const links_instruction: IBreadcrumbsLink[] = [
  { name: "АСКУЭ", href: `/${ROUTE_ASKUE_PAGE}` },
  { name: "Инструкции", href: `/${ROUTE_ASKUE_INSTRUCTIONS}` },
];

export const getNotifyAskue = (
  status?: keyof typeof EAskueMngStatus,
  createTime?: string,
  comment?: string,
) => {
  return status === "Принята" ? (
    `Список устройств обновлен  ${format(new Date(createTime ?? 0), "dd.MM.yyyy")}`
  ) : status === "В работе" ? (
    "Мы в процессе обработки заявки на подключение новых устройств. Во время обработки заявки добавление новых устройств невозможно"
  ) : status === "Отклонена" ? (
    <>
      Заявка на подключение устройств была отклонена. Возможно, загруженный файл поврежден или
      заполненный шаблон содержит ошибки. Пожалуйста, попробуйте еще раз загрузить данные или{" "}
      <SLink href={MAIL_SUPPORT}>обратитесь в службу поддержки</SLink>
    </>
  ) : (
    ""
  );
};

export const getStatusAskueMng = (value: string) => {
  if (!value) return "warning";

  switch (value) {
    case EAskueMngStatus["Принята"]:
      return "success";
    case EAskueMngStatus["В работе"]:
      return "warning";
    case EAskueMngStatus["Отклонена"]:
      return "error";
    default:
      return "warning";
  }
};

type ITags = {
  status: keyof typeof EAskueMngStatus;
  created_time: string | undefined;
  comment: string | undefined;
};

export const getBackendValues = (array: IAskueListResponse | undefined): ITags => {
  return {
    status: array?.status ?? "В работе",
    created_time: array?.created_time ?? undefined,
    comment: array?.comment ?? undefined,
  };
};

export const HARDCODE_LEVELS_FILTER = [
  {
    id: 0,
    name: DEPARTURE_RESULT.EVENT_INFORMATION,
    friendlyName: DEPARTURE_RESULT.EVENT_INFORMATION,
  },
  {
    id: 1,
    name: DEPARTURE_RESULT.EVENT_WARNING,
    friendlyName: DEPARTURE_RESULT.EVENT_WARNING,
  },
  {
    id: 2,
    name: DEPARTURE_RESULT.EVENT_CRASH,
    friendlyName: DEPARTURE_RESULT.EVENT_CRASH,
  },
];

const LEVEL_COLUMN = "level";
export const MODEL_COLUMN = "meter_name";
export const RELE_COLUMN = "rele_status";
export const HARDCODE_RELE_STATUSES = [
  {
    id: 0,
    name: 0,
    friendlyName: DEPARTURE_RESULT.DISABLED_RELE,
  },
  {
    id: 1,
    name: 1,
    friendlyName: DEPARTURE_RESULT.ENABLED_RELE,
  },
  {
    id: 2,
    name: 2,
    friendlyName: DEPARTURE_RESULT.NEW_DEVICE,
  },
];

export const ASKUE_LEVEL_COLUMN = { [LEVEL_COLUMN]: HARDCODE_LEVELS_FILTER };
export const ASKUE_RELE_COLUMN = { [RELE_COLUMN]: HARDCODE_RELE_STATUSES };

export const TABLE_FILTER_DEFAULT_EVENTS = {
  [LEVEL_COLUMN]: [],
  [MODEL_COLUMN]: [],
};
export const TABLE_FILTER_DEFAULT_ASKUE = {
  [RELE_COLUMN]: [],
  [MODEL_COLUMN]: [],
};

export const composeBackendFilter = (somearr): IAskueEventRequest =>
  somearr.reduce(
    (sum, cur) => (!isEmpty(cur.value) ? { ...sum, [cur.column]: cur.value } : { ...sum }),
    {},
  );
export const askueSettingsScheme = {
  switch: {
    visibility: ["ReactEpr", "ActEobr", "ReactEobr", "rele_status"],
  },
  radio: {
    details: ["halfhours", "hours", "days", "months", "quarters", "years"],
  },
};

export const askueDefaultSettings = { details: "days" };

export const defaultPeriod = { period: [null, null] };

export const cards = [
  {
    title: "Подключение SIM-карты",
    subtitle: "в случае если сим-карта поставляется отдельно",
    files: [
      {
        name: "Инструкция для Меркурий 234",
        href: instruction_1,
        size: "421 Кб",
      },
      {
        name: "Инструкция для Меркурий 204",
        href: instruction_2,
        size: "539 Кб",
      },
    ],
  },
  {
    title: "Подключение к сети",
    subtitle: "",
    files: [
      {
        name: "Инструкция для Меркурий 234",
        href: instruction_3,
        size: "464 Кб",
      },
      {
        name: "Инструкция для Меркурий 204",
        href: instruction_4,
        size: "484 Кб",
      },
    ],
  },
  {
    title: "Авторизация в личном кабинете EnergyTool Демо АСКУЭ ",
    subtitle: "",
    files: [
      {
        name: "Авторизация в ЛК EnergyTool АСКУЭ",
        href: instruction_5,
        size: "449 Кб",
      },
    ],
  },
  {
    title: 'Регистрация в личном кабинете Абонента "Энергосфера"',
    subtitle: "",
    files: [
      {
        name: "Регистрация в системе Энергосфера",
        href: instruction_6,
        size: "910 Кб",
      },
    ],
  },
];
