import { useContext } from "react";
import { IAskueResponse } from "interface/askue";
import { Spacer } from "components/spacer/spacer.component";
import Icon from "components/icons";
import { ASKUE_UNVER } from "services/urls";
import { PortsTable } from "./dashboardTables/portsTable";
import { TypesTable } from "./dashboardTables/typesTable";
import { CusTypo } from "components/cusTypo/custom-typography";
import { ContextSettings } from "context/context-settings";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";
import { IFilter } from "components/table/table-filter/table-filter";
import { ROUTE_ASKUE_DEVICES } from "stream-constants/route-constants";
import { ERele } from "../askueTable/utils";
import { RELE_COLUMN } from "pages/askue/const";
import { SPerc } from "pages/anomalies/styles";
import { MeterInfo, SDevicesGrid, SDown, SInf, SItem, SStatus, STypo, SWrap } from "./styles";

interface IProp {
  data: IAskueResponse;
}

export const AskueSummary: React.FC<IProp> = ({ data }) => {
  const { isMobile } = useContext(ContextSettings);

  return (
    <SDevicesGrid>
      {!isMobile() ? (
        <MetersInfoBlock data={data} />
      ) : (
        <SWrap className="meters">
          <MetersInfoBlock data={data} />
        </SWrap>
      )}

      <SItem className="item unver">
        <CusTypo variant={!isMobile() ? "h4_regular" : "c1_regular"}>
          Неопрошенные приборы учета
        </CusTypo>
        <Spacer value="16px" />
        <SInf>
          <STypo variant={!isMobile() ? "h2_medium" : "h4_medium"} font="wide">
            {data.meter_count_unverified}
          </STypo>
          <SDown href={ASKUE_UNVER}>Посмотреть список</SDown>
        </SInf>
      </SItem>
      <SItem className="item status">
        <CusTypo variant={!isMobile() ? "h4_regular" : "c1_regular"}>Состояние тенанта</CusTypo>

        <STypo variant={!isMobile() ? "h2_medium" : "h4_medium"} font="wide">
          <SStatus isOk={Boolean(data.status)}>{Boolean(data.status) ? "OK" : "Ошибка"}</SStatus>
        </STypo>
      </SItem>
      <SItem className="item time">
        <CusTypo variant={!isMobile() ? "h4_regular" : "c1_regular"}>Часовой пояс</CusTypo>

        <STypo variant={!isMobile() ? "h2_medium" : "h4_medium"} font="wide">
          {data.time_schema}
        </STypo>
      </SItem>
      <SItem className="chart">
        <CusTypo variant={!isMobile() ? "h4_regular" : "c1_regular"}>Типы устройств</CusTypo>
        <Spacer value="19px" />
        <TypesTable rawData={data.meter_types ?? []} />
      </SItem>
      <SItem className="ports">
        <CusTypo variant={!isMobile() ? "h4_regular" : "c1_regular"}>Активные порты</CusTypo>
        <Spacer value="19px" />
        <PortsTable rawData={data.active_ports ?? []} />
      </SItem>
    </SDevicesGrid>
  );
};

const MetersInfoBlock: React.FC<IProp> = ({ data }) => {
  const { isMobile } = useContext(ContextSettings);

  const storageFilter: IFilter =
    (StorageAPI.get(EStorageKeys.ASKUE_FILTER) &&
      JSON.parse(StorageAPI.get(EStorageKeys.ASKUE_FILTER))) ||
    [];

  const setAskueColumnFilter = (text: ERele | null) => {
    if (text !== null) {
      const res = [{ column: RELE_COLUMN, value: [text] }];
      let newStorage = [...storageFilter, ...res];
      StorageAPI.save(EStorageKeys.ASKUE_FILTER, JSON.stringify(newStorage));
    } else {
      StorageAPI.save(EStorageKeys.ASKUE_FILTER, JSON.stringify([]));
    }
  };

  return (
    <div className="top">
      <MeterInfo to={`/${ROUTE_ASKUE_DEVICES}`} onClick={() => setAskueColumnFilter(null)}>
        <CusTypo variant={!isMobile() ? "p3_regular" : "c1_regular"}>
          Всего подключено устройств
        </CusTypo>

        <SPerc variant={!isMobile() ? "h2_medium" : "h3_medium"} font="wide">
          {data.meter_count_all}
        </SPerc>
      </MeterInfo>
      {data.meter_new !== null ? (
        <MeterInfo
          color="grey"
          to={`/${ROUTE_ASKUE_DEVICES}`}
          onClick={() => setAskueColumnFilter(ERele["Новое устройство"])}
        >
          <CusTypo variant={!isMobile() ? "p3_regular" : "c1_regular"}>Новые устройства</CusTypo>

          <SPerc variant={!isMobile() ? "h2_medium" : "h3_medium"} font="wide">
            {data.meter_new}
          </SPerc>
        </MeterInfo>
      ) : null}
      <MeterInfo
        to={`/${ROUTE_ASKUE_DEVICES}`}
        color="green"
        onClick={() => setAskueColumnFilter(ERele["Реле включено"])}
      >
        {data.meter_rele_on ? (
          <>
            <CusTypo variant={!isMobile() ? "p3_regular" : "c1_regular"}>
              Устройства&nbsp;с включенным реле
            </CusTypo>

            <SPerc variant={!isMobile() ? "h2_medium" : "h3_medium"} font="wide">
              {data.meter_rele_on}
            </SPerc>
          </>
        ) : (
          <CusTypo
            variant={!isMobile() ? "p3_regular" : "c1_regular"}
            styles={{ flex: "1 1 100% !important" }}
          >
            Нет информации по включенным реле
          </CusTypo>
        )}
        <Icon.Success sx={{ width: 80, height: 80, opacity: 0.2 }} />
      </MeterInfo>
      <MeterInfo
        to={`/${ROUTE_ASKUE_DEVICES}`}
        color="red"
        onClick={() => setAskueColumnFilter(ERele["Реле отключено"])}
      >
        {data.meter_rele_off ? (
          <>
            <CusTypo variant={!isMobile() ? "p3_regular" : "c1_regular"}>
              Устройства&nbsp;с отключенным реле
            </CusTypo>

            <SPerc variant={!isMobile() ? "h2_medium" : "h3_medium"} font="wide">
              {data.meter_rele_off}
            </SPerc>
          </>
        ) : (
          <CusTypo
            variant={!isMobile() ? "p3_regular" : "c1_regular"}
            styles={{ flex: "1 1 100% !important" }}
          >
            Нет информации по отключенным реле
          </CusTypo>
        )}
        <Icon.Error sx={{ width: 80, height: 80, opacity: 0.2 }} />
      </MeterInfo>
    </div>
  );
};
