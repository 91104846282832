import { BillingCard } from "components/card-info/billing/billing.component";
import { RenderFilter } from "components/table/render-filter/renderFilter.component";

function multiSelectStatus(rows, columnIds, filterValue) {
  return filterValue.length === 0
    ? rows
    : rows.filter((row) => {
        const { bill_status } = row.original;
        return bill_status && filterValue.includes(String(bill_status));
      });
}

function multiSelectTenant(rows, columnIds, filterValue) {
  return filterValue.length === 0
    ? rows
    : rows.filter((row) => {
        const { tenant_id } = row.original;
        return tenant_id && filterValue.includes(String(tenant_id));
      });
}

export const tableStructure = [
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Номер счета"
        withPadding
      />
    ),
    accessor: "bill_id",
    minWidth: 160,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Организация"
        withPadding
      />
    ),
    accessor: "company_nm",
    minWidth: 200,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Дата"
        withPadding
      />
    ),
    accessor: "bill_dt",
    minWidth: 140,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Статус"
        withPadding
      />
    ),
    accessor: "bill_status",
    minWidth: 170,
    canFilter: true,
    filter: multiSelectStatus,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Сумма"
        withPadding
      />
    ),
    accessor: "bill_amount",
    minWidth: 180,
    width: "100%",
  },
  {
    accessor: "action",
    minWidth: 180,
    width: "100%",
  },
  {
    accessor: "tenant_id",
    minWidth: 0,
    maxWidth: 0,
    canFilter: true,
    filter: multiSelectTenant,
  },
];

export const tableMobStructure = [
  {
    accessor: "bill_id",
    Row: BillingCard,
  },
  {
    accessor: "company_nm",
    canFilter: true,
    filter: multiSelectTenant,
  },
  {
    accessor: "bill_dt",
  },
  {
    accessor: "bill_status",
    canFilter: true,
    filter: multiSelectStatus,
  },
  {
    accessor: "bill_amount",
  },
  {
    accessor: "tenant_id",
    canFilter: true,
    filter: multiSelectTenant,
  },
];
