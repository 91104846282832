import { useContext } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { Toast } from "components/toast/toast";
import { Loader } from "components/loader/loader";
import { Select } from "components/select/select";
import { CusTypo } from "components/cusTypo/custom-typography";
import { ContextSettings } from "context/context-settings";
import { useAuthChangeTenant, useUserTenants } from "hooks/api/management";
import { LIST_ROLES } from "utils/roles";
import { successHandler } from "./utils";
import { SDrop } from "./styles";

export const TenantSwitch = () => {
  const navigate = useNavigate();
  const { roles, profile, setProfile, isDesktop } = useContext(ContextSettings);
  const isRoot = roles.includes(LIST_ROLES.ADMIN) || roles.includes(LIST_ROLES.ANALYST_SYSTEM);
  const isAnal = roles.includes(LIST_ROLES.ANALYST_SYSTEM);
  const availTenants = useUserTenants(isRoot);
  const mutation = useAuthChangeTenant();

  const currTenant = profile?.current_tenant[0];
  const hasOnlyOne = availTenants?.data?.length === 1;
  const dropOptions = availTenants?.data
    ?.map((a) => ({
      val: a.tenant_id,
      friendlyName: a.title,
    }))
    .filter((a) => a.val !== currTenant?.id);
  const title = currTenant?.title ?? "Нет тенанта";

  return (
    <SDrop>
      {isDesktop() ? <span>Вы управляете:</span> : null}
      {availTenants.isLoading ? (
        <Loader />
      ) : hasOnlyOne ? (
        <CusTypo variant="p4_regular">{title}</CusTypo>
      ) : (
        <Select
          size={isDesktop() ? "S" : "M"}
          view={isDesktop() ? "minimal" : "standard"}
          label={isDesktop() ? undefined : "Вы управляете"}
          initValue={title}
          optionsList={dropOptions}
          onChange={(val) =>
            mutation.mutate(val, {
              onSuccess: async () => {
                toast(<Toast title="Текущий тенант изменен" />, {
                  progress: undefined,
                  autoClose: 2000,
                  hideProgressBar: true,
                });
                successHandler(navigate, setProfile, isAnal);
              },
              onError: () => {
                toast(<Toast title="Не удалось изменить тенант" isError />, {
                  progress: undefined,
                  autoClose: 2000,
                  hideProgressBar: true,
                });
              },
            })
          }
        />
      )}
    </SDrop>
  );
};
