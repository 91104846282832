import { IResearchHandbookRes } from "interface/analytics";
import { StatBubble } from "./subComponents";
import { SBlue } from "../researchTable/subComponents";
import { EMPTY_CELL_VALUE } from "stream-constants/keys-and-constants";

export const constructTableData = (draft: IResearchHandbookRes[]) =>
  draft.reduce((tot, cur) => {
    const { name_re } = cur;
    let copy = { ...tot };
    if (tot.hasOwnProperty(name_re)) {
      copy[name_re] = copy[name_re].concat(cur);
    } else {
      copy[name_re] = [cur];
    }
    return copy;
  }, {});

const renderCell = (val) => {
  switch (val) {
    case "name_re_color":
      return {
        Cell: ({ value }) => <StatBubble value={value} />,
      };
    case "val_score":
      return {
        Cell: ({ value }) => <SBlue>{value ?? EMPTY_CELL_VALUE}</SBlue>,
      };
    default:
      return {
        Cell: ({ value }) => value ?? EMPTY_CELL_VALUE,
      };
  }
};

export const constructColumns = ({ columns }) => {
  return columns.map((singleColumn) => {
    return {
      ...singleColumn,
      ...renderCell(singleColumn.accessor),
    };
  });
};
