import { IHttpMethods } from "stream-constants/server-constants";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";
import { fetchData } from "services/services-utils/fetch-data";
import {
  ASKUE_DEVICES,
  ASKUE_DEVICES_XLSX,
  ASKUE_EVENTS,
  ASKUE_EVENT_XLSX,
  ASKUE_GET_XLSX,
  ASKUE_INFO,
  ASKUE_RELE_LOG,
  ASKUE_SERVER,
  ASKUE_UPLOAD_XLSX,
} from "services/urls";
import { IAskueEventRequest, IAskueRequest, IAskueUpload } from "interface/askue";
import { askue_list_data, askue_data, mock_server_data, askue_events_data } from "./mock-data";

export const getAskueList = async (data?: IAskueRequest) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = askue_list_data;
  } else {
    result = await fetchData({
      url: ASKUE_DEVICES,
      method: IHttpMethods.POST,
      name: "getAskueList",
      body: JSON.stringify(data),
      emptyResponse: false,
      responseAbordTimeOut: false,
    });
  }
  return result;
};

export const getAskueXlsx = async () => {
  let result;

  result = await fetchData({
    url: ASKUE_GET_XLSX,
    method: IHttpMethods.GET,
    name: "getAskueTemplateXlsx",
    emptyResponse: false,
    blobResponse: true,
    responseAbordTimeOut: false,
  }).then((blob) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `askue_template.xlsx`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  });

  return result;
};

export const askueUploadStatement = async (data: IAskueUpload) => {
  return fetchData({
    url: ASKUE_UPLOAD_XLSX,
    method: IHttpMethods.POST,
    name: "askueUploadStatement",
    body: data,
    emptyResponse: false,
    contentType: "none",
  });
};

export const getAskue = async () => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = askue_data;
  } else {
    result = await fetchData({
      url: ASKUE_INFO,
      method: IHttpMethods.GET,
      name: "getAskue",
      emptyResponse: false,
      responseAbordTimeOut: false,
    });
  }
  return result;
};

export const getAskueServer = async () => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = mock_server_data;
  } else {
    result = await fetchData({
      url: ASKUE_SERVER,
      method: IHttpMethods.GET,
      name: "getAskueServer",
      emptyResponse: false,
    });
  }
  return result;
};

export const getAskueReleLog = async (data: { sn: string }) => {
  return await fetchData({
    url: ASKUE_RELE_LOG,
    method: IHttpMethods.POST,
    name: "getAskueReleLog",
    body: JSON.stringify(data),
    emptyResponse: false,
  });
};

export const getAskueEvents = async (data?: IAskueEventRequest) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = askue_events_data;
  } else {
    result = await fetchData({
      url: ASKUE_EVENTS,
      method: IHttpMethods.POST,
      name: "getAskueEvents",
      body: JSON.stringify(data),
      emptyResponse: false,
      responseAbordTimeOut: false,
    });
  }

  return result;
};

export const askueGetEventsXlsx = async (data?: IAskueEventRequest) => {
  let result;

  result = await fetchData({
    url: ASKUE_EVENT_XLSX,
    method: IHttpMethods.POST,
    name: "askueEventsLogXlsx",
    body: JSON.stringify(data),
    emptyResponse: false,
    blobResponse: true,
    responseAbordTimeOut: false,
  }).then((blob) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `askue_log.xlsx`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  });

  return result;
};

export const askueGetDeviceListXlsx = async (data?: IAskueRequest) => {
  return await fetchData({
    url: ASKUE_DEVICES_XLSX,
    method: IHttpMethods.POST,
    name: "askueEventsLogXlsx",
    body: JSON.stringify(data),
    emptyResponse: false,
    blobResponse: true,
    responseAbordTimeOut: false,
  }).then((blob) => {
    return blob;
  });
};
