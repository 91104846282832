import { SERVICES } from "stream-constants/keys-and-constants";
import { EDemoMngStatus, EDqScore, ESn, IRoles, ITenantFull } from "./common";
import { TENANT_DEPLOY_STATUS } from "components/card-info/tenant/const";

export enum EEventObjectType {
  "user_id" = "user_id",
  "tenant_id" = "tenant_id",
  "id_pp" = "id_pp",
}
export type IEvent = {
  eventType: string;
  eventDatetime: string;
  eventDesc: string;
  objectId: string | null;
  objectName: string | null;
  objectType: keyof typeof EEventObjectType | null;
};

export type IDemoMngStatuses =
  | "Утвердить"
  | "Отклонить"
  | "Приостановить"
  | "Восстановить"
  | "Удалить"
  | "Детализация данных";

export type IRequestStatuses = Exclude<IDemoMngStatuses, "Удалить" | "Детализация данных">;

export interface ITenantRoles {
  name: string | null;
}

export interface ITenantUsers {
  username: string | null;
  roles: ITenantRoles[];
}

// tenant доступен только Админу
export interface IUpdateUserRequest {
  tenantId?: string;
  email?: string;
  phone?: string | null;
  userName?: string;
  roles?: IRoles[];
}

export interface IUserResponse {
  tenant: ITenantFull | null;
  isActive: boolean | null;
  isResponsible: boolean;
  id: number;
  roles: IRoles[];
  createdTime: string;
  userName: string;
  phone: string;
  email: string;
}

export interface IUpdateTenant {
  contr_num?: string;
  time_schema?: string;
  additional_info?: string;
  service_type?: string[];
  phone?: string;
  email?: string;
  title?: string;
}

// tenant доступен только Админу
export interface IAddUserRequest {
  tenantId?: string;
  email: string;
  phone: string | null;
  userName: string;
  roles: IRoles[];
}

export interface IDemoChartResponse {
  id: number;
  measuredate: string; // "Sat, 01 Jan 2022 00:00:00 GMT" - utc стринга
  n_rate: 0 | 1 | 2; // 0 суммарное, 2 ночное, 1 дневное
  val: string;
}

export interface IDemoCreateRequest {
  email: string;
  userName: string;
  companyName: string;
  phone: string;
  inn: string;
}

export type IDemoDeleteRequest = {
  tenant_id: string;
  comment: string;
};

export interface IDemoFileUploadResponse {
  cnt: number;
  period_from: string; // "Wed, 01 Dec 2021 00:00:00 GMT"
  period_to: string;
}

export interface IDemoFormUploadRequest {
  id_nm: string;
  measure_type: string;
  val_type: string;
  discr_type: string;
  pu_owner_type: string[];
  tariff_type: string[];
  user_comment: string;
  address_flg: boolean;
  fraud_flg: boolean;
}

export interface IDemoFormUploadRequest {
  id_nm: string;
  measure_type: string;
  val_type: string;
  discr_type: string;
  pu_owner_type: string[];
  tariff_type: string[];
  user_comment: string;
  address_flg: boolean;
  fraud_flg: boolean;
}

export interface IDemoManagementResponse {
  id: string;
  created_time: string; // "2022-06-20T10:00:00",
  phone: string;
  title: string;
  status: EDemoMngStatus;
}

export interface IDemoMngScoreResponse {
  meter_cnt: number;
  period_from: string; // "Tue, 06 Dec 2022 00:00:00 GMT"
  period_to: string;
  consumer_type: string;
  change_flg: boolean;
  fraud_flg: boolean;
  TM: string; // 23.1
  dq_score: EDqScore;
  tariff_type: string;
  user_comment: string;
  measure_type: string;
  val_type: string;
  inn: string;
  email: string;
  discr_type: string;
  full_name?: string;
}

export interface IDemoMngUpdStatusRequest {
  id: string;
  comment?: string;
  status: IRequestStatuses;
}

export type IDemoScore = {
  address: string;
  id: number;
  score: string;
  sn: ESn;
};

export interface IDemoScoreResponse {
  results: IDemoScore[];
  isFailed?: boolean; // модерация данных провалена
  isModerating?: boolean;
}

export type IEventsResponse = {
  userName: string;
  roles: IRoles[];
  events: IEvent[];
};

export interface ITenantResponse {
  contr_num?: string | null;
  service_type?: string | null;
  title?: string | null;
  time_schema?: string | null;
  additional_info?: string | null;
  users?: ITenantUsers[];
  services: {
    service_type: SERVICES;
  }[];
}

export interface ITenantDeployStep {
  step: string;
  status: keyof typeof TENANT_DEPLOY_STATUS | null;
  date: string | null;
}

export interface IMenegementTenantResponse extends ITenantResponse {
  id: string;
  is_active: boolean;
  created_time?: string | null;
  phone?: string | null;
  email?: string | null;
  users?: any | null;
  deploy_steps: ITenantDeployStep[];
}

export interface IShopProdPromo {
  promotion_id: number;
  promotion_bubble: string;
}

export interface IShopProduct {
  id: number;
  name: string;
  type: string | null;
  category: string;
  conn_type: string;
  date: string; //"2020-12-12T00:00:00",
  image_url: string;
  price: number;
  price_type: string;
  promotions: IShopProdPromo[];
  desc: string;
  tooltip: boolean;
  redirect: null | string;
  docs: { [key: string]: string } | null;
}

export interface IUpdateProdCardRequest {
  id: number;
  category: string;
  type: string;
  name: string;
  price: number;
  price_type: string;
  description: string;
  docs: { [key: string]: string } | null;
}

export interface IMenegementBilling {
  company_nm: string | null;
  service_nm: string | null;
  bill_id: number;
  bill_dt: string;
  bill_amount: string | null;
  bill_status: string | null;
  tenant_id: string;
}

export interface ITariffRes {
  account_number: number;
  comment: string;
  contract_number: number;
  created_time: string; // 2024-08-12T10:37:50.272392
  price: number;
  service_code: string;
}

export interface ITariffReq {
  comment?: string;
  price: number;
  account_number: number;
  contract_number: number;
  service_code: string;
}
