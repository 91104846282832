import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { isEmpty, throttle } from "lodash";
import { ResizableBox } from "react-resizable";

import { ITableSortColumn, Table } from "components/table/table.component";
import { tableStructure } from "./const";
import {
  constructColumns,
  constructTableData,
  filterColumnsByVisibility,
  manualGlobFilter,
} from "./utils";
import { IResearch } from "interface/analytics";
import { ContextSettings } from "context/context-settings";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";

interface IProp {
  rawData: IResearch[];
  globalFilter?: string | any;
  onRowClick: (a: any) => void;
  rowSelected: number;
  showHints?: boolean;
  columnVisibility: { [key: string]: true };
  onRowsChange?: Function;
  onEndReach?: Function;
  sortByColumn?: ITableSortColumn[];
  setFavs: Function;
  favs: number[];
  withStub?: boolean;
  globalData?: any;
}
export const ResearchTable: React.FC<IProp> = ({
  rawData,
  globalFilter,
  onRowClick,
  rowSelected,
  showHints,
  columnVisibility,
  onRowsChange,
  onEndReach,
  sortByColumn,
  setFavs,
  favs,
  withStub,
  globalData,
}) => {
  const { isDesktop } = useContext(ContextSettings);
  const [tableHeight, setTableHeight] = useState(365);
  const tableData = useMemo(() => constructTableData(rawData), [rawData]);

  const columns = useMemo(
    () =>
      constructColumns({
        columns: filterColumnsByVisibility(tableStructure, columnVisibility),
        selectedId: rowSelected,
        showHints: showHints,
        favs: favs,
        setFavs,
      }),
    [rowSelected, showHints, columnVisibility, favs, setFavs],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRowsChange = useCallback((a) => onRowsChange?.(a), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleInfiniteLoading = useCallback(() => onEndReach, []);

  const lastClickedId = +StorageAPI.get(EStorageKeys.RESEARCH_SAVED);

  const handleRowClick = (v) => {
    onRowClick(v);
    StorageAPI.save(EStorageKeys.RESEARCH_SAVED, JSON.stringify(v));
  };

  const manageHeight = throttle((dt) => {
    const h = dt.size.height;
    setTableHeight(h);
    StorageAPI.save(EStorageKeys.RESEARCH_HEIGHT, JSON.stringify(h));
  }, 300);

  useEffect(() => {
    const idList = rawData?.map((a) => a.id_pp);
    if (!isEmpty(rawData) && (!lastClickedId || !idList.includes(lastClickedId)))
      onRowClick(rawData[0].id_pp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawData.length]);

  useEffect(() => {
    const strHeight = +StorageAPI.get(EStorageKeys.RESEARCH_HEIGHT) || 365;
    setTableHeight(strHeight);
  }, []);

  return isDesktop() ? (
    <ResizableBox
      height={tableHeight}
      minConstraints={[160, 160]}
      maxConstraints={[300, 700]}
      axis="y"
      onResize={(e, somedata) => manageHeight(somedata)}
    >
      <span>
        <Table
          columns={columns}
          data={tableData}
          view="sheets"
          onEndReach={handleInfiniteLoading}
          onRowClick={handleRowClick}
          onRowsChange={handleRowsChange}
          sortByColumn={sortByColumn}
          globalFilter={globalFilter}
          initSelRow={{ "0": true }}
          height={tableHeight}
          headerHeight={48}
          withFooter
          savedId={lastClickedId}
          globalFilterFunction={manualGlobFilter}
          withStub={withStub}
          globalData={globalData}
        />
      </span>
    </ResizableBox>
  ) : (
    <Table
      columns={columns}
      data={tableData}
      view="sheets"
      onEndReach={handleInfiniteLoading}
      onRowClick={handleRowClick}
      onRowsChange={handleRowsChange}
      sortByColumn={sortByColumn}
      globalFilter={globalFilter}
      initSelRow={{ "0": true }}
      height={300}
      headerHeight={48}
      withFooter
      savedId={lastClickedId}
      globalFilterFunction={manualGlobFilter}
      withStub={withStub}
      globalData={globalData}
    />
  );
};
