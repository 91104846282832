import { FC, Fragment, useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { yupResolver } from "@hookform/resolvers/yup";
import { MTSInput } from "components/mts-input";
import { MTSButton } from "components/mts-button/mts-button";
import { useUpdateProdCard } from "hooks/api/management";
import { MTSAutocomplete } from "components/mts-autocomplete/mts-autocomplete";
import { ModalNotifyBody } from "components/modal-notify-body/modal-notify-body";
import { FALLBACK_MSG, makeDefaultForEdit, schema } from "./validation-schema";
import { IShopProduct } from "interface/management";
import { Spacer } from "components/spacer/spacer.component";
import Icon from "components/icons";
import { InputLabel } from "components/input-label";
import { SModalTitle } from "components/modal-notify-body/styles";
import {
  EDevicesCategory,
  EDevicesCost,
  devicesType,
  makeListParams,
  rendSuffixPrice,
  servicesType,
} from "./const";
import {
  SAddNew,
  SBody,
  SBtns,
  SDelIcon,
  SDesc,
  SForm,
  SFormField,
  SInp,
  SPlug,
  SPrice,
  SWrapInp,
} from "./styles";

type IProps = {
  onCancel: Function;
  prodData: IShopProduct;
  setIsNotifyModal: (a) => void;
};

export const FormEditProduct: FC<IProps> = ({ onCancel, prodData, setIsNotifyModal }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    getValues,
    setValue,
  } = useForm<any>({
    defaultValues: makeDefaultForEdit(prodData),
    resolver: yupResolver(schema),
    mode: "onTouched",
    shouldFocusError: false,
    reValidateMode: "onChange",
  });

  const [docsChanged, setDocsChanged] = useState(false);
  const [watchPriceType] = useWatch({ control, name: ["price_type"] });
  const [watchCategory] = useWatch({ control, name: ["category"] });
  const [watchDocs] = useWatch({ control, name: ["docs"] });
  const { mutate, isSuccess, isError } = useUpdateProdCard();

  const handleAddDoc = () => {
    setValue("docs", [...getValues("docs"), { name: "", url: "" }]);
  };

  const handleRemoveDoc = (index: number) => {
    setValue(
      "docs",
      getValues("docs").filter((_, i) => i !== index),
    );
    setDocsChanged(true);
  };

  const onSubmit = (submData) => {
    const type = submData.type instanceof Array ? submData.type[0] : submData.type;
    const docs = submData.docs.reduce((acc, item) => {
      if (item.name !== "" && item.url !== "") {
        return {
          ...acc,
          [item.name]: item.url,
        };
      }
      return acc;
    }, {});

    mutate({
      id: prodData.id,
      category: submData.category,
      type: type,
      name: submData.name,
      price:
        submData.price_type !== "По запросу"
          ? Number(String(submData.price).replace(/\s+/g, ""))
          : -1,
      price_type: submData.price_type,
      description: submData.description,
      docs: Object.keys(docs).length !== 0 ? docs : null,
    });
  };

  useEffect(() => {
    reset(makeDefaultForEdit(prodData as IShopProduct));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, prodData]);

  useEffect(() => {
    prodData.category !== watchCategory ? setValue("type", []) : setValue("type", [prodData.type]);
  }, [getValues, prodData, setValue, watchCategory]);

  useEffect(() => {
    if (isSuccess || isError) setIsNotifyModal(true);
  }, [isSuccess, isError, setIsNotifyModal]);

  if (isSuccess) {
    return <ModalNotifyBody title="Редактирование успешно" isSuccess />;
  } else if (isError) {
    return (
      <ModalNotifyBody title="Редактирование не удалось" isError textDescrioption={FALLBACK_MSG} />
    );
  } else {
    return (
      <>
        <SModalTitle>Редактировать товар</SModalTitle>
        <SForm onSubmit={handleSubmit(onSubmit)}>
          <SBody>
            <div>
              <SFormField>
                {getValues("category") ? (
                  <MTSAutocomplete
                    label="Категория"
                    placeholder=""
                    options={makeListParams(EDevicesCategory)}
                    control={control}
                    size="M"
                    {...register("category", {
                      required: true,
                    })}
                    errorMessage={errors?.category?.message}
                  />
                ) : null}
              </SFormField>
              <SFormField>
                <MTSAutocomplete
                  label="Тип"
                  placeholder=""
                  options={
                    watchCategory === EDevicesCategory["Услуги и лицензии"]
                      ? servicesType
                      : watchCategory === EDevicesCategory["Счетчик учета электроэнергии"]
                      ? devicesType
                      : []
                  }
                  control={control}
                  size="M"
                  {...register("type")}
                  disabled={
                    !(
                      watchCategory === EDevicesCategory["Услуги и лицензии"] ||
                      watchCategory === EDevicesCategory["Счетчик учета электроэнергии"]
                    )
                  }
                  errorMessage={errors?.type?.message}
                />
              </SFormField>
              <SFormField>
                <MTSInput
                  label="Название"
                  control={control}
                  {...register("name", {
                    required: true,
                  })}
                  errorMessage={errors?.name?.message}
                />
              </SFormField>
              <SFormField>
                {getValues("price_type") ? (
                  <MTSAutocomplete
                    label="Цена"
                    placeholder=""
                    options={makeListParams(EDevicesCost)}
                    control={control}
                    size="M"
                    {...register("price_type", {
                      required: true,
                    })}
                    errorMessage={errors?.price_type?.message}
                  />
                ) : null}
              </SFormField>
              <SPrice>
                {watchPriceType !== "По запросу" ? (
                  <SFormField>
                    <Controller
                      name="price"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <NumericFormat
                          customInput={MTSInput}
                          suffix={rendSuffixPrice(watchPriceType)}
                          placeholder={`0 ${rendSuffixPrice(watchPriceType)}`}
                          onValueChange={(v) => {
                            onChange(Number(v.value));
                          }}
                          thousandSeparator={" "}
                          value={value}
                          allowNegative={false}
                        />
                      )}
                    />
                  </SFormField>
                ) : null}
              </SPrice>
              {prodData.category !== EDevicesCategory["Услуги и лицензии"] ? (
                <SFormField>
                  <Spacer value="16px" />
                  <InputLabel label="Документация" />
                  <Spacer value="4px" />
                  {watchDocs.map((field, index) => (
                    <Fragment key={field.id}>
                      <SWrapInp style={{ marginTop: index === 0 ? 0 : "8px" }}>
                        <SInp>
                          <MTSInput
                            placeholder="Название документации"
                            control={control}
                            {...register(`docs.${index}.name`, {
                              required: index === 0,
                            })}
                          />
                          {index !== 0 ? (
                            <SDelIcon
                              onClick={() => handleRemoveDoc(index)}
                              isError={!!errors?.docs?.[index]?.name.message}
                            >
                              <Icon.Close sx={{ width: 20 }} />
                            </SDelIcon>
                          ) : null}
                        </SInp>
                        <SInp>
                          <MTSInput
                            placeholder="Ссылка на документацию"
                            control={control}
                            {...register(`docs.${index}.url`, { required: index === 0 })}
                          />
                          {index !== 0 ? <SPlug /> : null}
                        </SInp>
                      </SWrapInp>
                      {index === watchDocs.length - 1 && (
                        <SAddNew>
                          <MTSButton
                            size="S"
                            variant="secondary"
                            onClick={() => handleAddDoc()}
                            icon={<Icon.Plus sx={{ width: 14, height: 14 }} />}
                            disabled={watchDocs.some((doc) => !doc.name.length || !doc.url.length)}
                          >
                            Новая документация
                          </MTSButton>
                        </SAddNew>
                      )}
                    </Fragment>
                  ))}
                </SFormField>
              ) : null}
            </div>
            <SDesc>
              <SFormField>
                <MTSInput
                  placeholder="Описание товара"
                  type="textarea"
                  label="Описание"
                  {...register("description", {
                    required: false,
                  })}
                  control={control}
                  errorMessage={errors?.description?.message}
                />
              </SFormField>
            </SDesc>
          </SBody>

          <Spacer value="24px" />
          <SBtns>
            <MTSButton size="M" variant="secondary" onClick={() => onCancel()}>
              Отмена
            </MTSButton>
            <MTSButton
              size="M"
              variant="primary"
              type="submit"
              onClick={() => undefined}
              disabled={!isDirty && !docsChanged}
            >
              Сохранить
            </MTSButton>
          </SBtns>
        </SForm>
      </>
    );
  }
};
