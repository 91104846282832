import { format } from "date-fns";

import {
  IMenegementBilling,
  IMenegementTenantResponse,
  ITenantDeployStep,
  IUserResponse,
} from "interface/management";
import { ITelebotRequest, IDeviceList } from "interface/anomalies";
import Icon from "components/icons";
import { CusTypo } from "components/cusTypo/custom-typography";
import { EMPTY_CELL_VALUE } from "stream-constants/keys-and-constants";
import { MTSStatus } from "components/mts-status/mts-status";
import { SelectAction, StateCode } from "components/devices/deviceTable/subComponents";
import { CopyToClipboard } from "components/copyToClipboard/copyToClipboard.component";
import { Spacer } from "components/spacer/spacer.component";
import { convertRoleToCyr } from "components/forms/user/validation-schema";
import { getPhoneWithSpaces } from "utils/formatters";
import { renderPriceByVal } from "components/management-shop/shopDevicesTable/utils";
import {
  TENANT_DEPLOY_STATUS,
  activeTenantDetailsKey,
  activeTenantTitles,
  maxDeploySteps,
} from "./tenant/const";
import { SBar, SBarBack, SBarTitle, SBarWrap, SSpecItem, SValueText } from "./styles";

export const sortHistoryCheckByDate = (x: ITelebotRequest, y: ITelebotRequest) => {
  const dateX = new Date(x.telebot_request_dt ?? 0);
  const dateY = new Date(y.telebot_request_dt ?? 0);
  if (dateX > dateY) return -1;
  if (dateX < dateY) return 1;

  return 0;
};

export const convertEmptyValue = (val?: string | null): string => val ?? EMPTY_CELL_VALUE;
export const convertManualFlg = (val: boolean) => (val ? "Ручной" : "Системный");

export const detailTitleByKey = {
  factory_count_num: "Серийный номер",
  adress_too: "Адрес",
  priem: "РЭС",
  fl_ul: "Потребитель",
  type_pu: "Тип ПУ",
  id_pp: "ID_pp",
  id_point: "ID_point",
  manual_flg: "Метод добавления ПУ",
};

export const historyTitleByKey = {
  telebot_request_dt: "Дата выезда",
  gaz_flg: "Газ",
  telebot_request_metter: "Фазы ПУ (АВС)",
  telebot_request_clamp: "Фазы клещи (АВС)",
  processed_dt: "Дата отчета",
  telebot_request_result: "Статус",
  telebot_request_comment: "Комментарий",
};

export const deviceCardTitleByKey = {
  telebot_request_last_result: "Статус",
  priem: "РЭС",
  telebot_request_last_dt: "Дата смены статуса",
  state_code: "Состояние",
};

export const userTitleByKey = {
  email: "Электронная почта",
  createdTime: "Дата регистрации",
  comment: "Комментарий",
};

export const billingTitleByKey = {
  bill_id: "Номер счета",
  bill_dt: "Дата выставления счета",
  bill_status: "Статус",
  company_nm: "Компания",
};

export const eventTitleByKey = {
  eventDatetime: "Дата и время",
  eventDesc: "Событие",
};

export const askueTitleByKey = {
  created_time: "Дата и время",
  comment: "Комментарий",
};

export const shopTitleByKey = {
  type: "Тип",
  price: "Цена",
};

export const makeTenantDetails = (someData: IDeviceList) => {
  const { factory_count_num, adress_too, priem, fl_ul, type_pu, id_pp, id_point, manual_flg } =
    someData;
  return {
    factory_count_num: {
      text: factory_count_num,
      value: convertEmptyValue(factory_count_num),
      isCanCopy: true,
    },
    adress_too: {
      text: adress_too,
      value: convertEmptyValue(adress_too),
      isCanCopy: true,
    },
    priem: {
      text: priem,
      value: convertEmptyValue(priem),
    },
    fl_ul: {
      text: fl_ul,
      value: convertEmptyValue(fl_ul),
    },
    type_pu: {
      text: type_pu,
      value: convertEmptyValue(type_pu),
    },
    id_pp: {
      text: id_pp,
      value: convertEmptyValue(String(id_pp)),
      isCanCopy: true,
    },
    id_point: {
      text: id_point,
      value: convertEmptyValue(String(id_point)),
      isCanCopy: true,
    },
    manual_flg: {
      text: convertManualFlg(manual_flg),
      value: convertManualFlg(manual_flg),
    },
  };
};

export const makeTenantDetailsMob = (someData: IDeviceList) => {
  const { fl_ul, type_pu, id_pp, id_point, manual_flg } = someData;
  return {
    fl_ul: {
      text: fl_ul,
      value: convertEmptyValue(fl_ul),
    },
    type_pu: {
      text: type_pu,
      value: convertEmptyValue(type_pu),
    },
    id_pp: {
      text: id_pp,
      value: convertEmptyValue(String(id_pp)),
      isCanCopy: true,
    },
    id_point: {
      text: id_point,
      value: convertEmptyValue(String(id_point)),
      isCanCopy: true,
    },
    manual_flg: {
      text: convertManualFlg(manual_flg),
      value: convertManualFlg(manual_flg),
    },
  };
};

export const makeTenantHistory = (
  data: IDeviceList,
  someData: ITelebotRequest,
  isMobile?: boolean,
) => {
  const {
    telebot_request_dt,
    gaz_flg,
    processed_dt,
    telebot_request_result,
    telebot_request_comment,
  } = someData;
  return {
    telebot_request_dt: {
      text: telebot_request_dt,
      value: telebot_request_dt
        ? format(new Date(telebot_request_dt ?? 0), "dd.MM.yyyy")
        : EMPTY_CELL_VALUE,
    },
    telebot_request_metter: {
      text: "",
      value: renderValueByPhaseNum(data.phase_num, someData, "meter", isMobile),
    },
    telebot_request_clamp: {
      text: "",
      value: renderValueByPhaseNum(data.phase_num, someData, "clamp", isMobile),
    },
    gaz_flg: {
      text: gaz_flg,
      value:
        data.gaz_flg === null ? (
          EMPTY_CELL_VALUE
        ) : data.gaz_flg ? (
          <Icon.CheckShaped sx={{ width: 18, height: 18 }} />
        ) : (
          <Icon.ErrorShaped sx={{ width: 18, height: 18 }} />
        ),
    },
    processed_dt: {
      text: processed_dt,
      value: format(new Date(processed_dt ?? 0), "dd.MM.yyyy"),
    },
    telebot_request_result: {
      text: telebot_request_result,
      value: MTSStatus(`${telebot_request_result}`, "small"),
    },
    telebot_request_comment: {
      text: telebot_request_comment,
      value: telebot_request_comment ? convertEmptyValue(String(telebot_request_comment)) : "",
    },
  };
};

export const renderValueByPhaseNum = (
  phase: string | null | undefined,
  data: ITelebotRequest,
  position: "clamp" | "meter",
  withTitle?: boolean,
) => (
  <div>
    {withTitle ? (
      <CusTypo variant="c1_regular">
        Фазы {position === "clamp" ? "клещи" : "ПУ"} {phase === "1" ? "(A)" : "(ABC)"}
      </CusTypo>
    ) : null}
    <CusTypo variant={withTitle ? "c1_medium" : "p4_regular"}>
      <span>{data[`val_${position}_A`]}</span>
      {phase === "3" ? <span>{data[`val_${position}_B`]}</span> : null}
      {phase === "3" ? <span>{data[`val_${position}_C`]}</span> : null}
    </CusTypo>
  </div>
);

const commonStyles = {
  width: 18,
  height: 18,
};

const renderIconStatusDeploy = (status: keyof typeof TENANT_DEPLOY_STATUS) => {
  switch (status) {
    case TENANT_DEPLOY_STATUS.Выполнено:
      return <Icon.CheckShaped sx={{ ...commonStyles }} />;
    case TENANT_DEPLOY_STATUS.Ошибка:
      return <Icon.ErrorShaped sx={{ ...commonStyles }} />;
    default:
      return null;
  }
};

export const getDeployStatus = (
  array: ITenantDeployStep[],
  checkStatus: keyof typeof TENANT_DEPLOY_STATUS,
) => array.filter((item) => item.status === TENANT_DEPLOY_STATUS[checkStatus]).length;

export const isActiveTenantCard = (steps: ITenantDeployStep[]): boolean =>
  getDeployStatus(steps, TENANT_DEPLOY_STATUS.Ошибка) === 0 &&
  getDeployStatus(steps, TENANT_DEPLOY_STATUS.Выполнено) === maxDeploySteps;

export const isErrorDeploy = (steps: ITenantDeployStep[]) =>
  getDeployStatus(steps, TENANT_DEPLOY_STATUS.Ошибка) > 0;

export const progressDeploy = (steps: ITenantDeployStep[]) =>
  steps.filter((item) => item.status === TENANT_DEPLOY_STATUS.Выполнено).length *
  (100 / maxDeploySteps);

export const activeTenantDetails = (data: IMenegementTenantResponse) => {
  return activeTenantDetailsKey.map((item) => {
    return {
      title: activeTenantTitles[item],
      value:
        item === "users"
          ? data[item].length || "Сотрудники не назначены"
          : item === "contr_num"
          ? `${data.contr_num ? "№" : ""}${convertEmptyValue(data.contr_num)}`
          : convertEmptyValue(data[item]),
    };
  });
};

export const deployedTenantDetails = (data: IMenegementTenantResponse) => {
  return data.deploy_steps.map((item) => {
    return {
      title: item.step,
      date: item.date ? format(new Date(item.date), "dd.MM.yyyy") : "",
      status: item.status,
    };
  });
};

export const renderStaffField = (data: IMenegementTenantResponse) => {
  return data.users.length > 0 ? (
    <>
      Сотрудники: <SValueText>{convertEmptyValue(data.users.length)}</SValueText>
    </>
  ) : (
    "Сотрудники не назначены"
  );
};

export const renderDeployedDetails = (data: IMenegementTenantResponse) => {
  return deployedTenantDetails(data).map((item) => {
    return (
      <SSpecItem color={item.status as any}>
        <CusTypo variant="c1_regular">{item.title}</CusTypo>
        {item.status ? (
          <div>
            <div>
              <CusTypo variant="c1_regular">
                Дата выполнения: <span>{item.date}</span>
              </CusTypo>
            </div>
            <span>
              <CusTypo variant="c1_bold">{item.status}</CusTypo>
              {renderIconStatusDeploy(item.status)}
            </span>
          </div>
        ) : null}
      </SSpecItem>
    );
  });
};

export const renderDeployedBar = (data: IMenegementTenantResponse) => (
  <SBarWrap isError={isErrorDeploy(data.deploy_steps)}>
    <SBar>
      <SBarBack width={progressDeploy(data.deploy_steps)} />
    </SBar>
    <SBarTitle>
      {progressDeploy(data.deploy_steps)}%{" "}
      {isErrorDeploy(data.deploy_steps) ? "Ошибка" : "Развертывание тенанта"}
    </SBarTitle>
  </SBarWrap>
);

export const makeDeviceCard = (data: IDeviceList) => {
  const {
    factory_count_num,
    telebot_request_last_result,
    adress_norm,
    priem,
    telebot_request_last_dt,
    state_code,
  } = data;
  return {
    factory_count_num: {
      text: factory_count_num,
      value: (
        <>
          <section>
            <CopyToClipboard text={String(factory_count_num)} variant="p3_bold" />
            <SelectAction id={String(factory_count_num)} code={state_code} />
          </section>
          <Spacer value="8px" />
        </>
      ),
    },
    adress_norm: {
      text: adress_norm,
      value: (
        <>
          <CusTypo variant="c1_regular">{convertEmptyValue(adress_norm)}</CusTypo>
          <Spacer value="14px" />
        </>
      ),
    },
    priem: {
      text: priem,
      value: convertEmptyValue(String(priem)),
    },
    telebot_request_last_result: {
      text: telebot_request_last_result,
      value: MTSStatus(telebot_request_last_result, "small"),
    },
    telebot_request_last_dt: {
      text: telebot_request_last_dt,
      value: format(new Date(telebot_request_last_dt ?? 0), "dd.MM.yyyy"),
    },
    state_code: {
      text: state_code,
      value: <StateCode code={state_code} />,
    },
  };
};

export const makeManagUserCard = (data) => {
  const { userName, tenant, roles, phone, isActive } = data;
  return {
    status: {
      text: "",
      value: isActive ? MTSStatus("Активен") : null,
    },
    userName: {
      text: "",
      value: convertEmptyValue(userName),
    },
    tenantTitle: {
      text: "",
      value: convertEmptyValue(tenant?.title),
    },
    roles: {
      text: "",
      value: roles?.map((a) => convertRoleToCyr(a.name)).join(", "),
    },
    phone: {
      text: "",
      value: convertEmptyValue(getPhoneWithSpaces(phone)),
    },
  };
};

export const makeManagUserInfo = (data: IUserResponse) => {
  const { email, createdTime } = data;
  return {
    email: {
      text: email,
      value: convertEmptyValue(email),
      isCanCopy: true,
    },
    createdTime: {
      text: createdTime,
      value: format(new Date(createdTime ?? 0), "dd.MM.yyyy"),
    },
  };
};

export const makeManagBillInfo = (data: IMenegementBilling) => {
  const { bill_id, bill_dt, bill_status, company_nm } = data;
  return {
    bill_id: {
      text: bill_id,
      value: convertEmptyValue(String(bill_id)),
      isCanCopy: true,
    },
    bill_dt: {
      text: bill_dt,
      value: format(new Date(bill_dt ?? 0), "dd.MM.yyyy"),
    },
    bill_status: {
      text: bill_status,
      value: MTSStatus(bill_status),
    },
    company_nm: {
      text: company_nm,
      value: convertEmptyValue(company_nm),
    },
  };
};

export const makeManagEventInfo = (data) => {
  const { eventDatetime, eventDesc } = data;
  return {
    eventDatetime: {
      text: eventDatetime,
      value: format(new Date(eventDatetime ?? 0), "dd.MM.yyyy"),
    },
    eventDesc: {
      text: eventDesc,
      value: convertEmptyValue(eventDesc),
    },
  };
};

export const makeManagAskueInfo = (data) => {
  const { created_time, comment } = data;
  return {
    created_time: {
      text: created_time,
      value: format(new Date(created_time ?? 0), "dd.MM.yyyy"),
    },
    comment: {
      text: comment,
      value: convertEmptyValue(comment),
    },
  };
};

export const makeManagShopInfo = (data) => {
  const { type, price, price_type } = data;
  return {
    type: {
      text: type,
      value: convertEmptyValue(type),
    },
    price: {
      text: price,
      value: renderPriceByVal(price, price_type),
    },
  };
};
