import { useContext, useEffect, useRef, useState } from "react";
import { isEmpty, uniqBy } from "lodash";
import { toast } from "react-toastify";

import { breakpoints } from "theme";
import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { Footer } from "components/footer/footer";
import { Loader } from "components/loader/loader";
import { MTSInput } from "components/mts-input";
import { AskueTable } from "components/askue/askueTable/askueTable.component";
import { ContextSettings } from "context/context-settings";
import { useDebounce } from "hooks/useDebounce";
import { useAskueGetDeviceXlsx, useGetAskueList } from "hooks/api/askue";
import { MTSModal } from "components/mts-modal/mts-modal";
import { FormAddAskue } from "components/forms/askue/form-add-askue";
import { Spacer } from "components/spacer/spacer.component";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";
import { CusTypo } from "components/cusTypo/custom-typography";
import { NotifyBlock } from "components/notification-block/notification-block";
import { EAskueMngStatus } from "interface/askue";
import { IFilter, TableFilter } from "components/table/table-filter/table-filter";
import { PopupAction } from "components/popup-action/popup-action";
import { ModalAction } from "components/modal-action/modal-action";
import { Toast } from "components/toast/toast";
import { query_objects_limit } from "hooks/api/const";
import {
  ASKUE_RELE_COLUMN,
  MODEL_COLUMN,
  TABLE_FILTER_DEFAULT_ASKUE,
  composeBackendFilter,
  getBackendValues,
  getNotifyAskue,
  getStatusAskueMng,
  links_devices,
} from "./const";
import { SArticle, SBtns, SFields, SItem, SSection, STitle } from "./styles";

interface IProp {
  isDemo?: boolean;
}

export const AskueListPage: React.FC<IProp> = ({ isDemo }) => {
  const [searchVal, setSearch] = useState("");
  const { isDesktop, getWidth } = useContext(ContextSettings);
  const debouncedSearch = useDebounce(searchVal, 500);
  const [status, setStatus] = useState("on");
  const [showModalAction, setShowModalAction] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [columnFilter, setColumnFilter] = useState<any>([]);
  const { data, fetchNextPage, isLoading, isSuccess } = useGetAskueList({
    ...composeBackendFilter([...columnFilter, { column: "search", value: debouncedSearch }]),
  });
  const { mutate: getXlsx } = useAskueGetDeviceXlsx();
  const askueResults = data?.pages.flatMap((p) => p.results) ?? [];
  const [dataLength, setDataLength] = useState(askueResults?.length);
  const askueData = getBackendValues(data?.pages[0]);

  const isFilterOn = StorageAPI.get(EStorageKeys.ASKUE_FILTER_IS) === "on";
  const storageFilter: IFilter =
    (StorageAPI.get(EStorageKeys.ASKUE_FILTER) &&
      JSON.parse(StorageAPI.get(EStorageKeys.ASKUE_FILTER))) ||
    [];

  const isStorageFilterAvail =
    isFilterOn && storageFilter && storageFilter?.some((a) => !isEmpty(a.value));

  const toggleModalAddAskue = () => setShowModalAction((s) => !s);

  const handleDownloadLogXlsx = () => {
    const pages = data?.pageParams ?? [];

    getXlsx(
      {
        ...composeBackendFilter([...columnFilter, { column: "search", value: debouncedSearch }]),
        obj_cnt: query_objects_limit,
        page_num: Number(pages[pages.length - 1] ?? 1),
      },
      {
        onError: (e) => {
          toastError(e?.description ?? "Ошибка загрузки");
        },
      },
    );
  };

  const toggleOnFilter = () => {
    const localStatus = StorageAPI.get(EStorageKeys.ASKUE_FILTER_IS);
    const res = !localStatus ? "on" : localStatus === "on" ? "off" : "on";
    StorageAPI.save(EStorageKeys.ASKUE_FILTER_IS, res);
  };

  const handleColumnFilter = ({ filtersArr }) => {
    if (!isEmpty(filtersArr) && isFilterOn) {
      StorageAPI.save(EStorageKeys.ASKUE_FILTER, JSON.stringify(filtersArr));
    }
    setColumnFilter(filtersArr);
  };

  const removeStorageFilter = () => {
    StorageAPI.remove(EStorageKeys.ASKUE_FILTER);
  };

  const handleInput = (e) => {
    setSearch(e.target.value);
  };

  const getDataLength = (a) => setDataLength(a.length);

  const dataPopup = [
    {
      title: "Подключить устройства",
      onClick: toggleModalAddAskue,
    },
    {
      title: "Скачать список",
      onClick: handleDownloadLogXlsx,
    },
  ];

  const isFirstQuery = useRef<boolean>(true);
  const schemeRef = useRef<any>({
    checkbox: {
      ...ASKUE_RELE_COLUMN,
      [MODEL_COLUMN]: [],
    },
  });

  useEffect(() => {
    const statusVal = StorageAPI.get(EStorageKeys.ASKUE_MNG_SUCCESS);
    if (
      statusVal === "off" &&
      (askueData?.status === EAskueMngStatus.Принята ||
        askueData?.status === EAskueMngStatus.Отклонена)
    )
      setStatus("off");
  }, [askueData?.status]);

  useEffect(() => {
    if (isSuccess) {
      if (
        askueData?.status === EAskueMngStatus.Принята ||
        askueData?.status === EAskueMngStatus.Отклонена
      )
        StorageAPI.save(EStorageKeys.ASKUE_MNG_SUCCESS, "off");
      else StorageAPI.save(EStorageKeys.ASKUE_MNG_SUCCESS, "on");
    }
  }, [isSuccess, askueData?.status]);

  useEffect(() => {
    if (isFirstQuery.current && !isEmpty(askueResults)) {
      const models =
        uniqBy(askueResults, "meter_name")?.map((raw, index) => ({
          id: index,
          name: raw.meter_name,
        })) ?? [];

      isFirstQuery.current = false;

      schemeRef.current = {
        checkbox: {
          ...ASKUE_RELE_COLUMN,
          [MODEL_COLUMN]: models,
        },
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [askueResults.length]);

  return (
    <>
      <div>
        <SSection>
          <Breadcrumbs links={links_devices} />
          <SArticle isFullTitle={isDemo}>
            <STitle>
              <CusTypo
                variant={isDesktop() ? "h2_medium" : "h4_medium"}
                font="wide"
                styles={{ margin: "0 24px 0 0" }}
              >
                Список устройств
              </CusTypo>
            </STitle>
            {!isDemo ? (
              <SFields>
                <SItem className="searchField">
                  <MTSInput
                    className="btns__filterSearch"
                    searchIcon
                    placeholder="Серийный номер, адрес"
                    onChange={handleInput}
                    value={searchVal}
                    size="S"
                  />
                </SItem>
              </SFields>
            ) : null}
            <SBtns>
              <TableFilter
                className="btns__filter"
                dataLength={dataLength ?? 0}
                isOpen={isOpenFilter}
                isOn={isFilterOn}
                toggleSwitch={toggleOnFilter}
                defaultFilter={
                  isStorageFilterAvail
                    ? storageFilter.reduce((total, amount) => {
                        return { ...total, [amount.column]: amount.value };
                      }, {})
                    : TABLE_FILTER_DEFAULT_ASKUE
                }
                toogleShowFilter={setIsOpenFilter}
                onApplyFilter={handleColumnFilter}
                onResetFilter={removeStorageFilter}
                scheme={schemeRef.current}
                isSchemeOnly
              />
              {getWidth() <= breakpoints.miniTablet ? (
                <ModalAction items={dataPopup} isLight={false} />
              ) : (
                <PopupAction items={dataPopup} />
              )}
            </SBtns>
          </SArticle>
          {status === "on" ? (
            <>
              <Spacer value="16px" />
              <NotifyBlock
                content={getNotifyAskue(askueData?.status, askueData?.created_time)}
                status={getStatusAskueMng(askueData?.status as any)}
              />
            </>
          ) : null}
        </SSection>
        <AskueTable
          rawData={askueResults ?? []}
          globalFilter={debouncedSearch}
          onRowsChange={getDataLength}
          onEndReach={fetchNextPage}
        />
      </div>
      <MTSModal open={showModalAction} close={toggleModalAddAskue}>
        <FormAddAskue onCancel={toggleModalAddAskue} />
      </MTSModal>

      <Footer />
      {isLoading ? <Loader isFullPage /> : null}
    </>
  );
};

const toastError = (str: string) => {
  toast(<Toast title={str} isError />, {
    progress: undefined,
    autoClose: 1500,
    hideProgressBar: true,
  });
};
