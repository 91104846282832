import { useState, useContext, useLayoutEffect } from "react";
import { isEmpty } from "lodash";
import { useLocation } from "react-router-dom";
import format from "date-fns/format";

import { breakpoints } from "theme";
import { toast } from "react-toastify";
import { useDeviceList } from "hooks/api/anomalies";
import { Footer } from "components/footer/footer";
import { getXlsxDeviceList } from "services/device-list";
import { Loader } from "components/loader/loader";
import { DeviceTable } from "../../components/devices/deviceTable/deviceTable.component";
import { MTSInput } from "components/mts-input";
import { useDebounce } from "hooks/useDebounce";
import { IFilter, TableFilter } from "components/table/table-filter/table-filter";
import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { IBreadcrumbsLink, ILocationState } from "interface/common";
import { ROUTE_ANOMALIES, ROUTE_DEVICE_LIST } from "stream-constants/route-constants";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";
import { ContextSettings } from "context/context-settings";
import { PopupAction } from "components/popup-action/popup-action";
import { TableSort } from "components/table/table-sort/table-sort";
import { InputDatePicker } from "components/mts-datepicker/mts-datepicker";
import { ArchiveFilter } from "components/devices/deviceTable/subComponents";
import { CusTypo } from "components/cusTypo/custom-typography";
import { MTSButton } from "components/mts-button/mts-button";
import Icon from "components/icons";
import { MTSModal } from "components/mts-modal/mts-modal";
import { FormAddResultCheck } from "components/forms/result-check/form-add-result-check";
import { FormAddDevice } from "components/forms/result-check/form-add-device";
import { ModalAction } from "components/modal-action/modal-action";
import { Toast } from "components/toast/toast";
import {
  FILTER_BY_CODE,
  FILTER_BY_STATUS,
  HARDCODE_STATUSES,
  TABLE_FILTER_DEFAULT_DEVICES,
  TABLE_SORT_DEFAULT_DEVICES,
  defaultPeriod,
} from "pages/anomalies/const";
import { SArticle, SBtns, SCont, SDash, SGroup, SPos, STitle } from "./styles";

const SCHEME_DEVICES = {
  checkbox: { [FILTER_BY_STATUS]: HARDCODE_STATUSES },
  radio: {},
};
const searchPhrase = "Адрес, РЭС, ID, серийный номер";
const linksBreadcrumbs: IBreadcrumbsLink[] = [
  { name: "Аномалии", href: `/${ROUTE_ANOMALIES}` },
  { name: "Устройства", href: `/${ROUTE_DEVICE_LIST}` },
];

export const DeviceListPage = () => {
  const { isDesktop, getWidth } = useContext(ContextSettings);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isOpenSort, setIsOpenSort] = useState(false);
  const [columnFilter, setColumnFilter] = useState<any>(undefined);
  const [columnSort, setColumnSort] = useState<any>([]);
  const [searchVal, setSearch] = useState("");
  const [period, setPeriod] = useState<any>(defaultPeriod);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showResModal, setShowResModal] = useState(false);
  const [deviceSn, setDeviceSn] = useState<string>("");
  const [archiveFilter, setArchiveFilter] = useState<string[]>(["Актуально"]);

  const { data, isLoading, isError, error } = useDeviceList(period);

  const locationState = useLocation().state as ILocationState;
  const debouncedSearch = useDebounce(searchVal, 500);
  const [currentData, setCurrent] = useState(() => (data ?? []).map((a) => a.factory_count_num));

  const isFilterOn = StorageAPI.get(EStorageKeys.ANMS_FILTER_IS) === "on";
  const storageFilter: IFilter = (
    (StorageAPI.get(EStorageKeys.ANMS_FILTER) &&
      JSON.parse(StorageAPI.get(EStorageKeys.ANMS_FILTER))) ||
    []
  )?.filter((a) => a.column !== FILTER_BY_CODE);

  const isStorageFilterAvail =
    isFilterOn && storageFilter && storageFilter?.some((a) => !isEmpty(a.value));

  const toggleOnFilter = () => {
    const localStatus = StorageAPI.get(EStorageKeys.ANMS_FILTER_IS);
    const res = !localStatus ? "on" : localStatus === "on" ? "off" : "on";
    StorageAPI.save(EStorageKeys.ANMS_FILTER_IS, res);
  };

  const handleDownloadFile = () => {
    if (!isEmpty(currentData)) getXlsxDeviceList(currentData);
  };

  const handleColumnFilter = ({ filtersArr }) => {
    if (!isEmpty(filtersArr) && isFilterOn) {
      StorageAPI.save(EStorageKeys.ANMS_FILTER, JSON.stringify(filtersArr));
    }
    setColumnFilter(filtersArr);
  };

  const handleColumnSort = (sorting) => setColumnSort(sorting);

  const removeStorageFilter = () => {
    StorageAPI.remove(EStorageKeys.ANMS_FILTER);
  };

  const handleInput = (e) => {
    setSearch(e.target.value);
  };

  const handleDatesPeriod = (e, key) => {
    setPeriod((s) => ({
      ...s,
      [key]: e ? format(new Date(e || undefined), "yyyy-MM-dd") : undefined,
    }));
  };

  const handleArchive = () => {
    const hasArchive = data && data?.filter((a) => a.state_code === "В архиве").length > 0;
    if (!hasArchive) {
      toastEmpty();
      setArchiveFilter(["Актуально"]);
    } else {
      setArchiveFilter((s) => {
        const newVal = s.includes("В архиве") ? ["Актуально"] : ["Актуально", "В архиве"];
        return newVal;
      });
    }
  };

  const handleRowsChange = (a) => setCurrent(a.map((b) => b.factory_count_num));

  const isNothingToShow = isError && error?.description !== null;

  const toggleAddModal = () => {
    setShowAddModal((s) => !s);
  };

  const toggleResModal = () => {
    setShowResModal((s) => !s);
  };

  const handleSetSn = (val: string) => {
    setDeviceSn(val);
  };

  const dataPopup = ([] as any)
    .concat(
      !isDesktop()
        ? [
            {
              title: "Сортировать список",
              onClick: () => setIsOpenSort(true),
            },
          ]
        : [],
    )
    .concat([
      {
        title: !isEmpty(currentData) ? "Скачать список" : "Нечего скачать",
        onClick: handleDownloadFile,
      },
    ]);

  useLayoutEffect(() => {
    return () => window.history.replaceState({}, "");
  });

  return (
    <>
      <SCont>
        <main>
          <section>
            <article>
              <Breadcrumbs links={linksBreadcrumbs} />
              {!isNothingToShow ? (
                <SArticle>
                  <STitle>
                    <CusTypo
                      variant={isDesktop() ? "h2_medium" : "h4_medium"}
                      font="wide"
                      styles={{ margin: "0 24px 0 0" }}
                    >
                      Список устройств
                    </CusTypo>
                    <MTSButton
                      size="S"
                      variant="primary"
                      onClick={toggleAddModal}
                      format="icon"
                      icon={<Icon.Plus sx={{ width: 24, height: 24 }} />}
                    />
                  </STitle>
                  <SBtns>
                    <MTSInput
                      className="btns__filterSearch"
                      searchIcon
                      placeholder={searchPhrase}
                      onChange={handleInput}
                      value={searchVal}
                      size="S"
                    />
                    <SPos>
                      <CusTypo variant="p4_medium">Период</CusTypo>
                      <InputDatePicker
                        placeholder="ДД.ММ.ГГ"
                        onSelect={(e) => handleDatesPeriod(e, "dt_from")}
                        isPickAM
                      />
                      <SDash>
                        <CusTypo variant="p4_bold">-</CusTypo>
                      </SDash>
                      <InputDatePicker
                        placeholder="ДД.ММ.ГГ"
                        onSelect={(e) => handleDatesPeriod(e, "dt_to")}
                        isPickAM
                      />
                    </SPos>
                  </SBtns>
                  <SGroup>
                    <TableFilter
                      className="btns__filter"
                      dataLength={currentData.length}
                      isOpen={isOpenFilter}
                      isOn={isFilterOn}
                      toggleSwitch={toggleOnFilter}
                      defaultFilter={
                        isStorageFilterAvail
                          ? storageFilter.reduce((total, amount) => {
                              return { ...total, [amount.column]: amount.value };
                            }, {})
                          : TABLE_FILTER_DEFAULT_DEVICES
                      }
                      toogleShowFilter={setIsOpenFilter}
                      onApplyFilter={handleColumnFilter}
                      onResetFilter={removeStorageFilter}
                      scheme={SCHEME_DEVICES}
                    />
                    <TableSort
                      items={TABLE_SORT_DEFAULT_DEVICES}
                      isOpen={isOpenSort}
                      close={() => setIsOpenSort(false)}
                      onApplySort={handleColumnSort}
                    />
                    <ArchiveFilter
                      withValue={archiveFilter.includes("В архиве")}
                      handleClick={handleArchive}
                    />
                    {getWidth() <= breakpoints.miniTablet ? (
                      <ModalAction items={dataPopup} isLight={false} />
                    ) : (
                      <PopupAction items={dataPopup} />
                    )}
                  </SGroup>
                </SArticle>
              ) : null}
            </article>
            <DeviceTable
              rawData={data ?? []}
              filterByColumn={columnFilter}
              sortByColumn={columnSort}
              onRowsChange={handleRowsChange}
              globalFilter={{
                archiveFilter,
                text: debouncedSearch,
              }}
              withStub={isNothingToShow}
              savedId={Number(locationState?.e_object_id)}
            />
          </section>
        </main>
        <Footer />
      </SCont>
      <MTSModal open={showAddModal} close={toggleAddModal}>
        <FormAddDevice
          inModal
          onMutation={handleSetSn}
          close={toggleAddModal}
          sucHandler={toggleResModal}
        />
      </MTSModal>
      <MTSModal open={showResModal} close={toggleResModal}>
        <FormAddResultCheck
          inModal
          close={toggleResModal}
          deviceSn={deviceSn}
          onMutation={toggleResModal}
        />
      </MTSModal>
      {isLoading ? <Loader isFullPage /> : null}
    </>
  );
};

const toastEmpty = () => {
  toast(<Toast title={"Архивные устройства отсутсвуют"} isError />, {
    progress: undefined,
    autoClose: 1500,
    hideProgressBar: true,
  });
};
